
















import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component<OHeader>({})
export default class OHeader extends Vue {
  @Prop({ default: 'wojtek' }) type!: 'wojtek'
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) content!: string
  @Prop({ type: Boolean, default: false }) showreel!: boolean
  @Prop({ type: Boolean, default: false }) wide!: boolean

  scrollPosition = 0

  mounted() {
    if (process.client) {
      window.addEventListener('scroll', this.onScroll)
      this.onScroll()
    }
  }

  onScroll() {
    if (process.client) {
      this.scrollPosition = window.scrollY
    }
  }

  toggleMode() {
    this.$store.commit('theme/switchMode')
  }
}
