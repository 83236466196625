




























import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageCareer>({})
export default class AHeaderImageCareer extends Vue {}
