




















































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageMobile>({})
export default class AHeaderImageMobile extends Vue {}
