
















































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageBranding>({})
export default class AHeaderImageBranding extends Vue {}
