










































































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageOutsourcing>({})
export default class AHeaderImageOutsourcing extends Vue {}
