

































































import { Component, Vue } from "nuxt-property-decorator";

@Component<AHeaderImageSwitch>({})
export default class AHeaderImageSwitch extends Vue {
  get mode() {
    return this.$store.state.theme.mode;
  }

  toggleMode() {
    this.$store.commit("theme/switchMode");

    if (this.$store.state.theme.mode == "dark") {
      this.$ga.event({
        eventCategory: "Light",
        eventAction: "OFF",
        eventLabel: "Światło zostało wyłączone"
      });
    } else {
      this.$ga.event({
        eventCategory: "Light",
        eventAction: "ON",
        eventLabel: "Światło zostało włączone"
      });
    }

    if ("vibrate" in navigator) {
      navigator.vibrate(10);
    }

    let audio = new Audio("/sfx/switch.mp3");
    audio.oncanplaythrough = () => {
      audio.play();
    };

    // if (this.$store.state.theme.mode == 'dark') {
    //   this.$toasted.show('Włączyłeś tryb ciemny. Możesz go wyłączyć w każdej chwili klikając włącznik na stronie o nas.', { duration: 2000 })
    // } else {
    //   this.$toasted.show('Wyłączyłeś tryb ciemny. Możesz go włączyć w każdej chwili klikając włącznik na stronie o nas.', { duration: 2000 })
    // }
  }
}
