import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=a5f0124a&lang=pug&"
import script from "./AppHeader.vue?vue&type=script&lang=ts&"
export * from "./AppHeader.vue?vue&type=script&lang=ts&"
import style0 from "./AppHeader.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppShowreelButton: require('/var/www/imoli.app/components/App/AppShowreel/AppShowreelButton.vue').default,AppHeaderImage: require('/var/www/imoli.app/components/App/AppHeader/AppHeaderImage/AppHeaderImage.vue').default})
