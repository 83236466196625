import { MetaInfo } from 'vue-meta'
import { joinURL } from 'ufo'
import config from '../config'

const baseUrl = config.cms.baseUrl

const headHelper = (meta: any, defaultMeta: MetaInfo = { title: undefined, meta: [] }): MetaInfo => {
  const head: MetaInfo = { title: undefined, meta: [], ...defaultMeta }

  if (meta) {
    // Title
    if (meta.title) head.title = meta.title

    // Standard meta
    if (meta.description) head.meta?.push({ hid: 'description', name: 'description', content: meta.description })
    if (meta.keywords) head.meta?.push({ hid: 'keywords', name: 'keywords', content: meta.keywords.join(',') })

    // OpenGraph
    if (meta.og_title) head.meta?.push({ hid: 'og:title', name: 'og:title', content: meta.og_title })
    if (meta.og_url) head.meta?.push({ hid: 'og:url', name: 'og:url', content: meta.og_url })
    if (meta.og_type) head.meta?.push({ hid: 'og:type', name: 'og:type', content: meta.og_type })
    if (meta.og_image) head.meta?.push({ hid: 'og:image', name: 'og:image', content: joinURL(baseUrl, 'assets', meta.og_image.id || meta.og_image) })
    if (meta.og_description) head.meta?.push({ hid: 'og:description', name: 'og:description', content: meta.og_description })

    if (meta.og_video) head.meta?.push({ hid: 'og:video', name: 'og:video', content: joinURL(baseUrl, 'assets', meta.og_video.id || meta.og_video) })
    if (meta.og_audio) head.meta?.push({ hid: 'og:audio', name: 'og:audio', content: joinURL(baseUrl, 'assets', meta.og_audio.id || meta.og_audio) })
    if (meta.og_locale) head.meta?.push({ hid: 'og:locale', name: 'og:locale', content: meta.og_locale })

    // if (meta.fb_app_id) head.meta?.push({ hid: 'fb:app_id', name: 'fb:app_id', content: meta.fb_app_id })

    // Twitter
    // TODO: add twitter meta tags
  }

  return head
}

export default headHelper
