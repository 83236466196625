







































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageWeb>({})
export default class AHeaderImageWeb extends Vue {}
