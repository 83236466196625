










































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageCrm>({})
export default class AHeaderImageCrm extends Vue {}
