

















import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageContact>({})
export default class AHeaderImageContact extends Vue {}
