















































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageWojtek>({})
export default class AHeaderImageWojtek extends Vue {}
