



















































































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageEai>({})
export default class AHeaderImageEai extends Vue {}
