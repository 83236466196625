


































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageErp>({})
export default class AHeaderImageErp extends Vue {}
