















import { Component, Vue, Watch } from 'nuxt-property-decorator'

@Component<AppShowreelButton>({})
export default class AppShowreelButton extends Vue {
  show() {
    this.$store.dispatch('showreel/show')
  }
}
