



















import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImagePortfolio>({})
export default class AHeaderImagePortfolio extends Vue {}
