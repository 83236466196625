






















import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageqa>({})
export default class AHeaderImageqa extends Vue {}
