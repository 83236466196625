












































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageSocialMedia>({})
export default class AHeaderImageSocialMedia extends Vue {}
