

































































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageUiux>({})
export default class AHeaderImageUiux extends Vue {}
