





















































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageseo>({})
export default class AHeaderImageseo extends Vue {}
