

















































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageFinancial>({})
export default class AHeaderImageFinancial extends Vue {}
