































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageMarketing>({})
export default class AHeaderImageMarketing extends Vue {}
