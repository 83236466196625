
























































































import { Component, Vue } from 'nuxt-property-decorator'

@Component<AHeaderImageEcommerce>({})
export default class AHeaderImageEcommerce extends Vue {}
