




































import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component<MHeaderImage>({})
export default class MHeaderImage extends Vue {
  @Prop({ default: 'wojtek' }) type!: 'wojtek'
}
